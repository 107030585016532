<template>
  <section class="section">
    <b-steps type="is-info" :has-navigation=false v-model="activeStep">
      <b-step-item label="Select files" icon="select"></b-step-item>
      <b-step-item label="Select languages" icon="progress-check"></b-step-item>
      <b-step-item label="Upload" icon="file-upload"></b-step-item>
      <b-step-item label="Translate" icon="translate"></b-step-item>
      <b-step-item label="Download" icon="file-download"></b-step-item>
      <b-step-item label="Done" icon="check"></b-step-item>
    </b-steps>

    <section>
      <!-- <b-field class="file">
        <b-upload v-model="file" expanded>
          <a class="button is-info is-fullwidth">
            <b-icon icon="upload"></b-icon>
            <span>{{ file.name || "Click to upload"}}</span>
          </a>
        </b-upload>
      </b-field> -->
      <b-field>
        <b-upload type="is-info" v-model="dropFiles" multiple drag-drop expanded accept=".docx">
          <section class="section">
            <div class="content has-text-centered">
              <p>
                <b-icon icon="upload" size="is-large"></b-icon>
              </p>
              <p>Drop your files here or click to upload</p>
            </div>
          </section>
        </b-upload>
      </b-field>

      <div class="tags">
        <span v-for="(file, index) in dropFiles" :key="index" class="tag is-info">
          {{file.name}}
          <button class="delete is-small" type="button" @click="deleteDropFile(index)"></button>
        </span>
      </div>

      <b-field label="Languages">
        <div>
          <b-checkbox type="is-info" v-for="(language, index) in languages" :key="index" @change="languagesChanged" v-model="selectedLanguages[language.value]" :native-value="language.value">{{ language.name }}</b-checkbox>
        </div>
      </b-field>

      <!-- <div>
        {{ getFilteredLanguages() }}
      </div> -->

      <div class="buttons">
        <b-button @click="uploadFiles" type="is-info" expanded>Upload</b-button>
      </div>

      <b-field v-if="activeStep == steps.translate" label="Translating...">
        <b-progress 
          type="is-info" 
          size="is-large"
          :precision="0"
          :value="progress * 100"
          format="percent"
          show-value
        ></b-progress>
      </b-field>

      <div v-if="downloadLink" class="buttons">
        <b-button @click="downloadFiles" type="is-success" expanded>Download {{(downloadLink.indexOf(".zip")<0) ? "translated file" : "zip"}}</b-button>
      </div>

      <b-notification v-if="notification"
        :type="(notification.type === 'error') ? 'is-danger is-light' : 'is-success is-light'"
        aria-close-label="Close notification"
        role="alert"
        :closable="false">
        {{notification.message}}
      </b-notification>

    </section>

  </section>
</template>

<script>
import {config} from '../config.js';

export default {
  data() {
    return {
      steps:{
        select: 0,
        languages: 1,
        upload: 2,
        translate: 3,
        download: 4,
        done: 5
      },

      activeStep: 0,
      file: {},
      dropFiles: [],

      languages: [
        { name: 'English', value: 'en' },
        { name: 'Spanish', value: 'es' },
        { name: 'French', value: 'fr' },
        { name: 'German', value: 'de' },
        { name: 'Italian', value: 'it' },
        { name: 'Portuguese', value: 'pt' },
        { name: 'Japanese', value: 'ja' },
        { name: 'Hungarian', value: 'hu' },
      ],
      selectedLanguages: {},
    };
  },
  props: [
    'downloadLink',
    'state',
    'progress',
    'notification'
  ],
  watch: {
    state:{
      handler: function(newValue, oldValue) {
        console.log('State changed from', oldValue, 'to', newValue);
        if(newValue === 'select')
          this.activeStep = this.steps.select;
        else if(newValue === 'upload')
          this.activeStep = this.steps.upload;
        else if(newValue === 'translate')
          this.activeStep = this.steps.translate;
        else if(newValue === 'download')
          this.activeStep = this.steps.download;
        else if(newValue === 'done')
          this.activeStep = this.steps.done;
      },
      immediate: true
    },
    dropFiles: {
      handler: function(newValue, oldValue) {
        this.dismissNotification();

        console.log('dropFiles changed from', oldValue, 'to', newValue);
        if(newValue.length > 0)
          this.setActiveStep(this.getFilteredLanguages().length > 0 ? this.steps.languages : this.steps.select);
        else
          this.setActiveStep(this.steps.select);
      },
      deep: true
    },
    selectedLanguages: {
      handler: function(newValue, oldValue) {
        this.dismissNotification();

        console.log('selectedLanguages changed from', oldValue, 'to', newValue);
        if(this.dropFiles.length > 0)
          this.setActiveStep(this.getFilteredLanguages().length > 0 ? this.steps.languages : this.steps.select);
        else
          this.setActiveStep(this.steps.select);
      },
      deep: true
    }
  },
  
  methods: {
    dismissNotification() {
      console.log('Dismiss notification');
      //if(this.notification)
        this.$emit('message', 'dismiss');
    },

    setActiveStep(step) {
      this.activeStep = step;
    },

    languagesChanged() {
      console.log('Languages changed, number of selected languages:', this.getFilteredLanguages().length);
    },

    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },

    uploadFiles() {
      this.setActiveStep(this.steps.upload);

      const formData = new FormData();

      //append files
      for (let i = 0; i < this.dropFiles.length; i++) {
        formData.append('files', this.dropFiles[i]);
      }

      //append selected languages from checkboxes
      const selectedLanguages = this.getFilteredLanguages().join(",");
      formData.append('languages', selectedLanguages);

      console.log('Emitting upload event');
      this.$emit('upload', formData);
    },

    downloadFiles() {
      console.log('Downloading file: ', this.downloadLink);
      window.open(config.serverURL + "/download/"+this.downloadLink, '_blank');

      this.setActiveStep(this.steps.done);
      this.$emit('message', 'downloaded');
      console.log('File downloaded successfully!')
    },

    getFilteredLanguages() {
      return Object.entries(this.selectedLanguages).filter(([, value]) => value === true).map(([key]) => key);
    }
  }
};
</script>

<style>
  .responsive-b-field {
    display: flex;
    flex-wrap: wrap;
  }

  .responsive-b-field > .b-field:not(:last-child) {
    margin-right: 10px; /* Adjust spacing between b-fields */
    margin-bottom: 10px; /* Adjust vertical spacing between lines */
  }
</style>
