<!-- App.vue -->

<template>
  <div>
    <FileUpload @upload="handleUpload" @message="handleMessage" :downloadLink="downloadLink" :state="state" :progress="progress" :notification="notification"></FileUpload>
  </div>
</template>

<script>
import FileUpload from './components/FileUpload.vue';
import axios from 'axios';
import {config} from './config.js';

export default {
  components: {
    FileUpload,
  },

  data() {
    return {
      downloadLink: null,
      state: 'select',
      progress: 0,
      notification: null
    };
  },

  methods: {
    setState(newState) {
      if(this.state === newState)
        return;

      console.log('Setting state to', newState);

      switch(newState) {
        case 'upload':
          this.downloadLink = null;
          this.state = 'upload';
          break;
        case 'translate':
          this.state = 'translate';
          this.progress = 0;
          break;
        case 'download':
          this.state = 'download';
          break;
        case 'done':
          this.state = 'done';
          break;
        default:
          this.state = 'select';
      }
    },

    async handleUpload(formData) {
      try {
        //check if there are files to upload and if the user has selected languages
        if(formData.getAll('files').length === 0 || formData.getAll('languages').length === 0) {
          console.log('No files or languages selected');

          this.notification = {
            type: 'error',
            message: 'Please select files and languages'
          };
          return;
        }

        this.setState('upload');
        this.startTimer();

        console.log('Uploading files');

        if(!document.cookie.includes('connect.sid')){
          console.log('Getting session cookie first');
          await axios.get(config.serverURL + '/status');
        }

        const response = await axios.post(config.serverURL + '/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          responseType: 'json'
        });

        this.stopTimer();
        this.setState('download');

        console.log('Response data:', response.data);
        //create download url for response.data.file or response.data.zipFile
        this.downloadLink = (response.data.file) ? response.data.file : response.data.zipFile;
      } catch (error) {
        console.error('Error uploading files:', error);
        this.stopTimer();
        this.setState('select');
      }
    },

    startTimer() {
      // Set the timer to run every 1 seconds (adjust the interval as needed)
      this.timerId = setInterval(this.fetchDataFromBackend, 1000);
    },

    stopTimer() {
      // Stop the timer when the component is destroyed or no longer needed
      clearInterval(this.timerId);
    },

    async fetchDataFromBackend() {
      console.log('Polling backend...');

      const response = await axios.get(config.serverURL + '/status');
      console.log('Response data:', response.data);

      switch(response.data.state) {
        case 'upload':
          this.setState('upload');
          break;
        case 'translate':
          this.setState('translate');
          break;
      }

      if(response.data.progress)
        this.progress = response.data.progress;
    },

    handleMessage(message) {
      switch(message) {
        case 'dismiss':
          console.log('Dismissing notification:', message);
          //handle emit dismissNotification from FileUpload
          this.notification = null;
          break;

        case 'downloaded':
          console.log('Clearing download link');
          this.downloadLink = null;
          this.setState('done');
          break;
      }
    }
  },

  async mounted() {
      this.timerId=0;
  },

  beforeUnmount() {
    // Make sure to stop the timer when the component is destroyed
    this.stopTimer();
  },

}
</script>

<style>
  /* Import your CSS file */
  @import "@/assets/styles.css";
</style>
